import * as React from 'react';
import { useLocation } from 'react-router-dom';
import Logo from './Logo';

import { FiLogOut, FiUser } from 'react-icons/fi'
import useResponsive from '../../hooks/useResponsive';
import MobileMenu from '../Menus/MobileMenu';
import { AppContext } from '../../context/AppContext';
import ToolTip from '../UI/Tooltip';

import { getAuth, signOut } from "@firebase/auth";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/reducers/userSlice';

const Header = () => {
    const dispatch = useDispatch()
    const location = useLocation()

    const { isMobile } = useResponsive()

    const appContext = React.useContext(AppContext)
    const { menuOpen, setMenuOpen } = appContext

    const userData = useSelector((state: any) => state.user.userData)


    const auth = getAuth();



    const onLogout = () => {
        // const auth = getAuth();
        signOut(auth).then(() => {
            // Sign-out successful.
            dispatch(logout({
                callback: () => {
                    window.location.href = "https://www.mavienumerique.fr/";
                },
            }))
        }).catch((error) => {
            // An error happened.
            toast.error("Error during signout", {
                position: "top-right",
                autoClose: 1500,
            })
        });
    }


    const renderRightItems = () => {
        if (location.pathname == "/dashboard") {
            return <>
                {/* mobile menu */}
                <MobileMenu
                    onClick={() => setMenuOpen(!menuOpen)}
                    isOpen={menuOpen}
                />
                <div className='absolute inset-y-0 right-0 flex flex-col justify-center sm:flex'>

                    {/* desktop menu */}


                    <ToolTip
                        button={
                            <div className='hidden sm:flex bg-[#F3F3F3] rounded-lg h-[38px] items-center px-3 gap-2 mr-20'>
                                <div className='bg-[grey] w-[30px] h-[30px] rounded-[50%]'>
                                    {userData?.photoURL && <img className='w-full h-full rounded-[50%]'
                                        src={userData?.photoURL}
                                    />
                                    }
                                </div>
                                <div>{userData?.displayName}</div>
                            </div>
                        }
                        content={<div
                            onClick={onLogout}
                            className='flex items-center rounded-[8px] gap-2 px-[20px] text-[18px] h-[50px] bg-white cursor-pointer'>
                            <FiLogOut />
                            <div className='font-outfit font-semibold' >Se déconnecter</div>
                        </div>}
                        panelStyles={{
                            padding: 0,
                            top: 50,
                            borderRadius: 8,
                            right: 79
                        }}
                        trigger={"click"}
                    />
                </div>
            </>
        }
        return <div></div>
    }

    return <>
        <header
            style={{
                ...(menuOpen && { position: 'fixed', zIndex: 100, right: 0, left: 0, backgroundColor: '#F5F5F5' })
            }}
            className='relative flex py-3 px-[16px] sm:px-[50px] md:px-[70px] lg:px-[100px] items-center justify-between sm:justify-start bg-white h-[76px]'
        >
            <div >
                <Logo />
            </div>
            {renderRightItems()}
        </header>
    </>
}

export default Header