import React from 'react';
import { useSelector } from 'react-redux';
import {
    Navigate, useLocation, useNavigate,
} from 'react-router-dom';
import { SocketProvider } from '../context/SocketContext';

const ProtectedRoute = ({ children }: any) => {
    const userState = useSelector((state: any) => state.user)

    const location = useLocation()

    const navigate = useNavigate()

    React.useEffect(() => {
        if (!userState.loggedIn) {
            //if user is logged out from dashboard, then redirect to Onboarding 1 page
            if (location.pathname === "/dashboard") {
                navigate('/', {
                    state: { from: location }
                })
            } else { // else redirect to signup ( for example an expired oAuth token on Steps page should trigger this)
                navigate('/signup', {
                    state: { from: location }
                })
            }
        }
    }, [userState.loggedIn])

    // if (!userState.loggedIn) {
    //     return <Navigate to="/signup" replace state={{ from: location }} />;
    // }

    return <SocketProvider>{children}</SocketProvider>; // Wrap the children with SocketProvider


    return children;
};


export default ProtectedRoute