// context/SocketContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import io from 'socket.io-client';
import { RootState } from '../store';
import { useSelector } from 'react-redux';

const SocketContext = createContext<any>(null);

export const useSocket = () => {
    return useContext(SocketContext);
};

export const SocketProvider = ({ children }: any) => {
    const [socket, setSocket] = useState<any>(null);

    const userData: any = useSelector((state: RootState) => state.user.userData);

    useEffect(() => {
        if (userData?.uid) {
            const socketInstance = io(process.env.REACT_APP_API_BASE_URL, {
                query: { userId: userData?.uid },
            });

            setSocket(socketInstance);

            return () => {
                socketInstance.disconnect();
            };
        }
    }, [userData?.uid]);

    return (
        <SocketContext.Provider value={{ socket }}>
            {children}
        </SocketContext.Provider>
    );
};
