import * as React from 'react'

import * as JsSvgs from '../assets/js_svgs'

import { ProgressBar } from "react-step-progress-bar";

import { BiSearch } from 'react-icons/bi'
import { FiFilter, FiChevronUp, FiChevronDown } from 'react-icons/fi'

import netflixImg from '../assets/images/netflix.png'
import amazonImg from '../assets/images/amazon.png'
import instagramImg from '../assets/images/instagram.png'
import AppItem from '../components/AppItem';
import AppButton from '../components/UI/AppButton';

import { Menu } from "@headlessui/react";
import PopularFilter from '../components/Filters/PopularFilter';
import OtherFilters from '../components/Filters/OtherFilters';
import ToolTip from '../components/UI/Tooltip';
import useResponsive from '../hooks/useResponsive';

import { SlClose } from 'react-icons/sl'
import MobileFilterMenu from '../components/Menus/FilterMenu';
import { useGetProfileQuery } from '../store/rtk-query/profileApi';
import { useSelector } from 'react-redux';
import { User } from '@firebase/auth';
import { useGetAccountsQuery, usePullMailDomainsMutation } from '../store/rtk-query/accountApi';
import { useGetDashboardSummaryQuery } from '../store/rtk-query/dashboardApi';
import { addClassNames } from '../utils/functions';
import StickyDiv from '../components/StickyDiv';
import DashboardMetrics from '../components/dashboard/DashboardMetrics';
import { DashboadFilterFormValues } from '../@types/appTypes';
import { useFormik } from 'formik';
import { Oval } from 'react-loader-spinner';
import FormReceivedModal from '../components/dashboard/FormReceivedModal';
import SubmitFormModal from '../components/dashboard/SubmitFormModal';

import io, { Socket } from 'socket.io-client';
import { toast } from 'react-toastify';
import { UserReducerState } from '../store/reducers/userSlice';
import { RootState } from '../store';
import { useSocket } from '../context/SocketContext';

const popularity = [
    'Ordre alphabétique croissant',
    'Ordre alphabétique décroissant',
    'Les plus populaires',
    'Les moins populaires',
    'Les mieux bien noté',
    'Les moins bien noté'
]

type ClickSourceType = undefined | 'other' | 'supprimerButton'

const Dashboard = () => {

    const MAX_BACKEND_CHECKS = 5;
    const COUNT_TO_SHOW_LOAD_BANNER = 2

    //check interval
    const dbCheckInterval = 5 * 1000 //5 seconds

    // wait time before modal display
    const waitTimeForModalDisplay = 45 * 1000 // 45 seconds

    /**
     * refs */

    // last time profile was checked for accounts count
    const lastRunTimestamp = React.useRef<number | null>(Date.now());
    //
    const trackedPercent = React.useRef<number>(0);
    const backendCheckCount = React.useRef<number>(0); //how many times has app checked backend for accounts length
    const backendAccountsNoTrack = React.useRef<number[]>([]) // an array to track the last 4 account lengths retrieved
    const backendSocketsThreadsNoTrack = React.useRef<number[]>([]) // an array to track the last 4 account lengths retrieved

    const modalDisplayTimeout = React.useRef<NodeJS.Timeout | undefined>(undefined)

    //socket thread count states
    const [currentThreadCount, setCurrentThreadCount] = React.useState(0);

    //filter states
    const [showPopularFilter, setShowPopular] = React.useState(false)
    const [showOtherFilter, setShowOtherFilter] = React.useState(false)

    //
    const [modalTimedPopupShown, setModalTimedPopupShown] = React.useState<boolean>(false)

    /**
     * Custom way to know if backend is currently pulling emails
     *
    * default as true, so that get profile is called at least thrice
     **/
    const [isBackendGettingAccounts, setIsGettingAccounts] = React.useState<boolean>(true)

    /** */
    const [showMobileFilter, setShowMobileFilter] = React.useState(false)

    //
    const [searchTerm, setSearchTerm] = React.useState<string | undefined>(undefined)

    //for top bar banner
    const [showFormSubmitBanner, setShowFormSubmitBanner] = React.useState<boolean>(false)

    //for form popups
    const [showFormModal, setShowFormModal] = React.useState<boolean>(false)
    const [showFormClickSource, setShowFormSource] = React.useState<ClickSourceType>(undefined)


    const userState = useSelector((state: RootState) => state.user)

    const socket: Socket = useSocket().socket

    const { isMobile, isTablet, isDesktop } = useResponsive();

    const [pullMails, pullMailsState] = usePullMailDomainsMutation({ fixedCacheKey: "stepsPagePullKey" })

    const { currentData: profileData, data: oldProfileData, isFetching: profileFetching, isUninitialized: profileUninitialized } = useGetProfileQuery({}, {
        //if mails are still being pulled, then refresh profile information every 10 seconds
        pollingInterval: (pullMailsState.isLoading || isBackendGettingAccounts) ? dbCheckInterval : 0,
    })

    // boolean to determine form to show
    const isFormSubmitted: boolean = React.useMemo(() => {
        if (profileData?.user?.formSubmitted == undefined) return false
        return profileData?.user?.formSubmitted
    }, [profileData?.user?.formSubmitted]);


    // formik for dashboard filters
    const initialFormValues: DashboadFilterFormValues = {
        activeCats: [],
        activeGrades: [],
        activeSort: ''
    }
    const formik = useFormik({
        initialValues: initialFormValues,
        onSubmit: (values) => {

        }
    })

    const formatGradeForFilter = React.useMemo(() => {
        return formik.values.activeGrades.map((grade) => {
            let formatted = grade?.replace('Grade', '').trim()
            return formatted
        })
    }, [formik.values])

    let queryParams = {
        ...(formik.values.activeSort && { orderBy: formik.values.activeSort }),
        ...(formatGradeForFilter.length > 0 && { selectedGrades: formatGradeForFilter }),
        ...(searchTerm && { search: searchTerm })
    }

    const { data: accountsData, isFetching: accountsIsFetching } = useGetAccountsQuery({
        queryParams
    },
        {
            //if mails are still being pulled, then refresh profile information every 10 seconds
            pollingInterval: (pullMailsState.isLoading || isBackendGettingAccounts) ? dbCheckInterval : 0
        }
    )

    const { data: dashboardData, isLoading: dashboardLoading, isFetching } = useGetDashboardSummaryQuery({}, {
        //if mails are still being pulled, then refresh profile information every 10 seconds
        pollingInterval: (pullMailsState.isLoading || isBackendGettingAccounts) ? dbCheckInterval : 0,
    })


    const userData: User = useSelector((state: any) => state.user.userData)

    /**
     * Use effect to handle loading state
     */
    React.useEffect(() => {
        const allEqual = (arr: any[]) => arr.every(val => val === arr[0]);

        // Track the timestamp of the last effect run
        const currentTimestamp = Date.now();

        // Check if enough time (5 seconds) has passed since the last run
        // this effect must run in intervals of 6seconds or more
        if (lastRunTimestamp.current && currentTimestamp - lastRunTimestamp.current < 6000) {
            return; // Exit if the effect is being triggered too soon
        }

        // Update the timestamp for future reference
        lastRunTimestamp.current = currentTimestamp;

        // If it's still fetching or uninitialized, ignore the effect
        // if (profileFetching || profileUninitialized) return;

        // Counter to track backend accounts.length check
        backendCheckCount.current = backendCheckCount.current + 1;

        backendSocketsThreadsNoTrack.current = [
            ...backendSocketsThreadsNoTrack.current,
            currentThreadCount
        ]


        const last5ThreadsCount = backendSocketsThreadsNoTrack.current.slice(-5);
        // const last3ThreadsCount = backendSocketsThreadsNoTrack.current.slice(-3);

        // If no change in number of accounts after 5, then turn off custom backend interval checker
        if (backendSocketsThreadsNoTrack.current.length >= MAX_BACKEND_CHECKS && allEqual(last5ThreadsCount)) {
            setIsGettingAccounts(false);
        }

    }, [profileFetching, profileData?.user?.noOfAccounts, currentThreadCount]);

    /**
     * use effect to handle timer modal popup
     */
    React.useEffect(() => {
        // Ensure the modal is only shown once
        if (modalTimedPopupShown) return;

        /**
         * Check if form submission status is undefined or true.
         * - undefined means data is still loading.
         * - true means the form has already been submitted, so no need to show the modal.
         */
        if (profileData?.user?.formSubmitted === undefined || profileData?.user?.formSubmitted === true) {
            // Clear any existing timeout if the form is submitted or data is loading
            clearTimeout(modalDisplayTimeout.current);
        }

        // Check if the form has not been submitted and other conditions are met
        if (profileData?.user?.formSubmitted === false) {
            // Only start the timeout if:
            // - The mail state is not loading (pullMailState.isLoading is false)
            // - The backend is not fetching accounts (isBackendGettingAccounts is false)
            // - The user has not already submitted the form (profileData?.user?.formSubmitted === false)


            if (!pullMailsState.isLoading && !isBackendGettingAccounts && profileData?.user?.formSubmitted === false) {
                // console.log("Conditions met. Starting countdown...");

                // Start the timeout to show the modal after the specified wait time
                modalDisplayTimeout.current = setTimeout(() => {
                    // Display the form modal
                    setShowFormModal(true);
                    // Clear the timeout after the modal is shown
                    clearTimeout(modalDisplayTimeout.current);
                    // Mark the modal as shown to prevent showing it again
                    setModalTimedPopupShown(true);
                }, waitTimeForModalDisplay);
            }
        }
    }, [profileData?.user?.formSubmitted, pullMailsState.isLoading, isBackendGettingAccounts]);

    /**
     *  UseEffect for socket listening of threadcount
     */
    React.useEffect(() => {
        if (!socket) return

        // Listen for thread count updates
        socket.on('threadCountUpdate', (data) => {
            // console.log('socket data ', data)
            setCurrentThreadCount(data.currentThreadCount);
        });
    }, [userState?.userData, socket])


    const onOpenFormLink = () => {
        if (!userData?.email) {
            toast.error("User details not available yet", {
                position: "top-right",
                autoClose: 1500,
            })
            return
        }

        const email = userData?.email;
        const url = `https://tally.so/r/wAz7v0?email=${email}`;

        window.open(url, '_blank');
        //show banner
        setShowFormSubmitBanner(true)
        setShowFormModal(false)
    }

    const renderTopInfo = () => {
        if (showFormSubmitBanner) {
            return <StickyDiv>
                <div
                >
                    <div className=' bg-[#F3F0FF] h-[84px] md:h-[60px] px-[16px] flex items-center gap-[12px] justify-center text-[#03042480]'>
                        <div className=''>
                            <JsSvgs.HeartIcon />
                        </div>
                        <div className='font-regular font-outift text-mavien-purple' >
                            Nous avons bien reçu vos réponses. Nous reviendrons bientôt vers vous !
                        </div>
                        <div className='absolute right-2 mb-[-3px]'>
                            <JsSvgs.CloseIcon
                                onClick={() => setShowFormSubmitBanner(false)}
                            />
                        </div>
                    </div>
                </div>
            </StickyDiv>
        }

        if (pullMailsState.isLoading || (isBackendGettingAccounts && backendCheckCount.current > COUNT_TO_SHOW_LOAD_BANNER)) {
            return <StickyDiv>
                <div
                >
                    <div className='bg-[#F3F0FF] h-[84px] md:h-[60px] px-[16px] flex items-center gap-[14px] justify-center text-[#03042480]'>
                        <Oval
                            height={18}
                            width={18}
                            color="#6640FF"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#FFFFFF"
                            strokeWidth={4}
                            strokeWidthSecondary={3}

                        />
                        <div className='font-regular font-outift text-mavien-purple' >
                            L'analyse de votre vie numérique est toujours en cours... cela peut prendre quelques minutes
                        </div>
                    </div>
                </div>
            </StickyDiv>
        }

    }

    const renderFilters = () => {
        let input_class = 'text-[#030424] bg-[#fff] border-2 border-[#E7E7E7] hover:border-[#AAAAAA] focus-within:border-[#AAAAAA] p-2 pl-[22px] font-medium rounded-[40px] text-[18px] flex items-center h-[48px] min-w-[150px] justify-between'

        return (
            <div className='flex gap-1 sm:gap-2 mb-[14px] md:mb-[16px]'>
                <div className={`${input_class} flex-1`}>
                    <input
                        placeholder='Rechercher'
                        className='w-full h-full'
                        value={searchTerm ? searchTerm : ''}
                        onChange={(e) => { setSearchTerm(e.target.value) }}
                    />
                    <BiSearch
                        size={22}
                        color='#B3B3B3'
                    />
                </div>
                <OtherFilters
                    open={showOtherFilter}
                    onClick={() => setShowOtherFilter(!showOtherFilter)}
                    initialValues={initialFormValues}
                    formik={formik}
                />
                <PopularFilter
                    open={showPopularFilter}
                    onClick={() => setShowPopular(!showPopularFilter)}
                    initialValues={initialFormValues}
                    formik={formik}
                />
                <div
                    onClick={() => {
                        setShowMobileFilter(!showMobileFilter)
                    }}
                    className=' sm:hidden bg-[#F5F5F5] rounded-[40px] p-2 flex w-[48px] justify-center items-center'>
                    <JsSvgs.FilterIcon
                        transform='scale(0.8)'
                    />
                </div>
            </div>
        )
    }


    return <div className='w-100 bg-[#F9F9F9] pb-[70px] min-h-[150vh]'>
        {
            renderTopInfo()
        }

        <div
            className={
                addClassNames(
                    'mx-[16px] mb-[50px] md:mx-[18%] xl:mx-[auto] ',
                    'mt-[30px] md:mt-[66px]',
                    'max-w-[920px]',
                )
            }
        >
            <div className=' mb-[70px]'>
                <div className='space-y-[4px] mb-[29px]'>
                    <div className='font-[600] text-[30px]'>Bienvenue {profileData?.user?.username}</div>
                    <div className='text-[#03042480] '>{userData?.email}</div>
                </div>
                <DashboardMetrics
                    isFormSubmitted={isFormSubmitted}
                    profileData={profileData}
                    onShowForm={() => setShowFormModal(true)}
                />
            </div>
            <div className=''>
                <div className='flex gap-2 font-[600] text-[22px] sm:text-[28px] items-center mb-[14px]'>
                    <div>Comptes détectés</div>
                    <div className=' text-white bg-mavien-purple rounded-2xl text-[16px] h-[24px] w-[45px] rounded-[20px] flex items-center justify-center'><span>
                        {dashboardData?.totalCount || 0}
                    </span></div>
                </div>
                {renderFilters()}
                <div className={
                    addClassNames(
                        'gap-[17px]',
                        //use grid on default screens
                        'grid grid-cols-2',
                        //
                        'sm:grid-cols-dashboard sm:justify-between'
                    )
                }>
                    {accountsData?.accounts &&
                        accountsData?.accounts?.map((item: any,) => {
                            return <AppItem
                                key={item?._id} data={item}
                                onClick={(data) => {
                                    setShowFormModal(true)
                                    setShowFormSource('supprimerButton')
                                }}
                            />
                        })
                    }
                </div>
            </div>
        </div>
        <MobileFilterMenu
            open={showMobileFilter}
            onClick={() => setShowMobileFilter(!showMobileFilter)}
            initialValues={initialFormValues}
            formik={formik}
        />

        <FormReceivedModal
            show={showFormModal && isFormSubmitted}
            onClose={() => {
                setShowFormModal(false)
                setShowFormSource(undefined)
            }}
            onProceed={onOpenFormLink}
            clickSource={showFormClickSource}
        />
        <SubmitFormModal
            show={showFormModal && !isFormSubmitted}
            onClose={() => {
                setShowFormModal(false)
                setShowFormSource(undefined)
            }}
            onProceed={onOpenFormLink}
            clickSource={showFormClickSource}
        />
    </div>
}

export default Dashboard