import * as React from 'react'

import { useNavigate } from 'react-router-dom';


import * as JsSvgs from '../assets/js_svgs'

import { ReactComponent as Googlereviews } from '../assets/googleReviews.svg'

import scannerMan from '../assets/images/scannerMan.png'
import labLady from '../assets/images/labLady.png'
import ToolTip from '../components/UI/Tooltip';
import useResponsive from '../hooks/useResponsive';

import { MdClose } from 'react-icons/md'

import { getAuth, signInWithPopup, GoogleAuthProvider } from "@firebase/auth";
import { initializeApp } from '@firebase/app';
import { toast } from 'react-toastify';
import { useLoginMutation } from '../store/rtk-query/userApi';
import { useDispatch } from 'react-redux';
import { updateToken } from '../store/reducers/userSlice';
import { addClassNames } from '../utils/functions';
import Spinner from '../components/UI/Spinner';

// import { initializeApp } from "@firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyB1HrKNFhbHFM2kwKrl5w6Ly_d7fxWJs4o",
    authDomain: "mavienumerique-7507b.firebaseapp.com",
    projectId: "mavienumerique-7507b",
    storageBucket: "mavienumerique-7507b.appspot.com",
    messagingSenderId: "812958862649",
    appId: "1:812958862649:web:1f98dfab27c1ff795a2ce8"
};

const app = initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();

googleProvider.addScope('https://www.googleapis.com/auth/gmail.readonly');
googleProvider.addScope('https://www.googleapis.com/auth/user.gender.read');
googleProvider.addScope('https://www.googleapis.com/auth/user.birthday.read');

interface ButtonItem {
    icon: JSX.Element,
    title: string,
    action?: () => any;
    extraclass?: string
}

const SignUp = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const [login, loginState] = useLoginMutation()

    const [oauthLoading, setOAuthLoading] = React.useState(false)

    const { isMobile, isDesktop, isTablet } = useResponsive()
    const [showTip, setShowTip] = React.useState<boolean>(false)


    const googleSignin = async () => {
        const auth = getAuth();

        setOAuthLoading(true)

        signInWithPopup(auth, googleProvider)
            .then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const accessToken = credential?.accessToken;
                // The signed-in user info.
                const user = result.user;

                const idToken = await user.getIdToken()

                dispatch(updateToken({ accessToken: accessToken }))

                const res: any = await login({
                    idToken,
                    accessToken
                })

                if (!res.data) {
                    toast.error("Error during signin", {
                        position: "top-right",
                        autoClose: 1500,
                    })
                }
                setOAuthLoading(false)

            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...

                toast.error("Error during signin", {
                    position: "top-right",
                    autoClose: 1500,
                })
                setOAuthLoading(false)
            });
    }

    const buttonsData: ButtonItem[] = [
        {
            icon: <JsSvgs.GoogleIcon />,
            title: 'Se connecter avec Google',
            action: googleSignin
        },
        {
            icon: <JsSvgs.MicrosoftIcon />,
            title: 'Microsoft - Bientôt disponible',
            // action: () => navigate('/steps'),
            extraclass: 'bg-[#ECECEC] border-[#ECECEC] hover:border-[#ECECEC] cursor-[default]'
        },
        {
            icon: <JsSvgs.YahooIcon />,
            title: 'Yahoo - Bientôt disponible',
            // action: () => navigate('/steps'),
            extraclass: 'bg-[#ECECEC] border-[#ECECEC] hover:border-[#ECECEC] cursor-[default]'
        },
    ]


    const renderButtons = () => {
        return (
            <div
                className='flex flex-col '
            >
                {buttonsData.map((item) => {
                    return (
                        <button
                            key={item.title}
                            onClick={item?.action ? item.action : undefined}
                            className={
                                addClassNames(
                                    'rounded-[28px]',
                                    'mb-[10px]',
                                    'w-full min-w-[83%] sm:min-w-[320px] max-w-[360px] flex gap-2 px-6',
                                    'items-center h-[50px] border-2 border-[#03042433] hover:border-[#AAAAAA]',
                                    item.extraclass
                                )
                            }
                        ><>
                                <div className='mr-[15px]'>{item.icon}</div>
                                <span className='text-[16px] font-[400] font-outfit'>{item.title}</span>
                            </>
                        </button>
                    )
                })}
            </div>
        )
    }

    const renderPopup = () => {

        return (
            <>
                <div className='flex gap-1 items-center text-[#0304244D] mt-[20px]'>
                    <span>
                        <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z" fill="#030424" fillOpacity="0.3" />
                            <path d="M6.38281 6.30051C6.59441 5.69901 7.01205 5.19181 7.56177 4.86873C8.11149 4.54565 8.75782 4.42756 9.38627 4.53535C10.0147 4.64315 10.5847 4.96988 10.9954 5.45769C11.406 5.94549 11.6308 6.56288 11.6298 7.20051C11.6298 9.00051 8.92981 9.90051 8.92981 9.90051" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9 13.5H9.01" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                    <ToolTip
                        button={<span onClick={() => setShowTip(!showTip)} className='underline font-medium'>Pourquoi avez<span className='font-[400]'>-</span>vous besoin de mon e-mail ?</span>}
                        // trigger='click'
                        open={showTip}
                        content={<div className='text-black'>
                            <div
                                onClick={() => setShowTip(!showTip)}
                                className='cursor-pointer ml-[auto] w-[fit-content]'><MdClose size={25} /></div>
                            <div className='font-bold text-[32px] sm:text-[36px] leading-[44px] mb-[20px]'>Connectez votre email pour que nous puissions decouvrir les entreprises qui detiennent vos donnees.
                            </div>
                            <div className='text-[18px] leading-[27px]'>La technologie de Mavienumérique.fr compile la liste des entreprises avec lesquelles vous avez interagi par le passé en analysant l'objet des messages, l'adresse de l'expéditeur et le nombre de fois qu'ils apparaissent dans votre boîte de réception.
                            </div><br />
                            <div className='text-[18px] leading-[27px]'>
                                Mavienumérique.fr fait tout cela sans collecter aucun de vos messages électroniques.
                                Vos données restent véritablement les vôtres.
                            </div>
                        </div>}
                        panelStyles={{
                            padding: 19,
                            ...(isMobile && { width: '100%' }),
                            ...(!isMobile && { top: 'auto', bottom: 'calc( 2em + 15px )', width: 600, right: '-298px' }),
                            ...(isDesktop && { width: 800, right: '-490px' }),
                        }}
                    />

                </div>
            </>
        )
    }

    const renderLoader = () => {
        return <div style={{ minHeight: 'calc( 100vh - 100px )' }} className='w-screen h-full flex justify-center items-center'>
            <Spinner />
        </div>
    }

    if (oauthLoading) {
        return renderLoader()
    }

    return <div className='px-[16px] sm:px-[50px] md:px-[70px] lg:px-[100px]'>
        <div className='flex mx-auto'>
            <div className=' w-full sm:w-[60%] py-[30px] sm:py-[40px]'>
                <div className={
                    addClassNames(
                        'text-2xl text-[32px] leading-[38px] md:leading-[60px] md:text-[54px] font-[600] font-outfit',
                        'mb-[30px]'
                    )
                }>Faites le bilan sur votre <br />
                    <span className='text-mavien-purple'>vie numérique</span>
                </div>
                <div className='mb-[14px] mt-4 font-medium font-outfit'>Connectez-vous pour commencer :</div>
                {renderButtons()}
                {
                    // renderPopup()
                }
                <div className='leading-[22px] text-[#0304244D] text-[14px] md:text-[16px] mt-[51px] sm:mt-[92px]  max-w-[350px]'>
                    L'utilisation et le transfert par mavienumerique.fr d'informations reçues des API de Google vers toute autre application seront conformes à la{' '}
                    <a
                        target={'_blank'}
                        className='underline'
                        href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                    >politique de données utilisateur des services API de Google</a>,
                    y compris les exigences d'utilisation limitée.<br /><br />
                    mavienumerique.fr ne partagera aucune donnée avec d'autres applications tiers.
                </div>
                {/* <div className='mt-[42px]'>
                    <Googlereviews />
                </div> */}
                {/* Privacy Policy */}
                {/* <div className='mt-[39px] text-[#0304244D] text-[16px] leading-[22px]'>
                    En vous connectant, vous acceptez notre{' '}
                    <span className='underline'>politique de confidentialité et nos conditions d'utilisation.</span>
                </div> */}
            </div>
            <div className='hidden sm:block h-[52vh]'>
                <img className='h-full w-full object-contain ml-[40px] mt-[60px]' src={labLady} />
            </div>
        </div>

    </div>
}

export default SignUp