import { createSlice } from "@reduxjs/toolkit";
import { userApi } from "../rtk-query/userApi";

const initialState = {
  loggedIn: false,
  userData: undefined,
  idToken: undefined,
  accessToken: undefined,
  refreshToken: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // loginSuccess: (state, action) => {
    //   state.loggedIn = true;
    //   state.idToken = action.payload.idToken;
    //   state.refreshToken = action.payload.refreshToken;
    // },
    logout: (state, action) => {
      // Check if a callback is provided and execute it
      if (action.payload?.callback) {
        action.payload.callback();
      }

      state.loggedIn = false;
      state.idToken = undefined;
      state.userData = undefined;
      state.refreshToken = undefined;
    },
    updateToken: (state, action) => {
      if (action.payload.idToken) {
        state.idToken = action.payload.idToken;
      }
      if (action.payload.accessToken) {
        state.accessToken = action.payload.accessToken;
      }
      if (action.payload.refreshToken) {
        state.refreshToken = action.payload.refreshToken;
      }
      if (action.payload.userData) {
        state.userData = action.payload.userData;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        // state.idToken = payload.idToken;
        // state.refreshToken = payload.refreshToken;
        state.loggedIn = true;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const { logout, updateToken } = userSlice.actions;

export default userSlice.reducer;

// Automatically infer the type of the state
export type UserState = typeof initialState;

// Infer the state type from the slice's reducer
export type UserReducerState = ReturnType<typeof userSlice.reducer>;
