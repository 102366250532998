import * as React from 'react'
import AccountsLoadingOverlay from '../components/AccountsLoadingOverlay'

import StepsProgressBar from '../components/StepsProgressBar'
import AppButton from '../components/UI/AppButton'

import { ProgressBar, Step } from "react-step-progress-bar";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import StepsSlider from '../components/StepsSlider';
import { useGetProfileQuery, useUpdateProfileMutation } from '../store/rtk-query/profileApi';
import { toast } from 'react-toastify';
import { usePullMailDomainsMutation } from '../store/rtk-query/accountApi';
import Spinner from '../components/UI/Spinner';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from '@firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { logout, updateToken } from '../store/reducers/userSlice';

const googleProvider = new GoogleAuthProvider();

googleProvider.addScope('https://www.googleapis.com/auth/gmail.readonly');
googleProvider.addScope('https://www.googleapis.com/auth/user.gender.read');
googleProvider.addScope('https://www.googleapis.com/auth/user.birthday.read');


const Steps = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();

    //minimum number of threads before redirecting
    const minCheckB4Redirect: number = 50

    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [percent, setPercent] = React.useState<number>(100)

    const [touched, setTouched] = React.useState<boolean>(false)

    //used to display loading overlay for a few seconds even if user has already pulled over {minCheckB4Redirect} threads
    const [isFakeLoading, setIsFakeLoading] = React.useState<boolean>(false)

    const [updateProfile, updateProfileState] = useUpdateProfileMutation()

    const [pullMails, pullMailsState] = usePullMailDomainsMutation({ fixedCacheKey: "stepsPagePullKey" })

    // number of email threads pulled tracked in react ref
    const trackedCountedThreads = React.useRef(0)

    const { data: profileData, isLoading: profileLoading } = useGetProfileQuery({}, {
        //if mails are still being pulled and its still less than 100 mail threads
        // then refresh profile information every 10 seconds
        ...(pullMailsState.isLoading && { pollingInterval: (trackedCountedThreads.current < minCheckB4Redirect) ? 10 * 1000 : 0 })
    })

    if (profileData?.user?.countedThreads) {
        trackedCountedThreads.current = profileData?.user?.countedThreads
    }

    const userState = useSelector((state: any) => state.user)

    React.useEffect(() => {
        /**
         * only pull accounts
         * - if user has not pulled threads up to minimum for redirect to dashboard
         * - if pull mail api is not already running
         */

        let countedThreads = 0;

        if (profileData?.user?.countedThreads) {
            countedThreads = profileData?.user?.countedThreads
        }

        if (profileData && countedThreads < minCheckB4Redirect && !pullMailsState.isLoading) {
            pullMailAccounts()
        }
    }, [profileData])

    /**
    * Show Loading screen while email domains are being pulled
    *
    * loading screen only shows if api is loading and no of guessed accounts has been submitted
    *  OR if {isFakeLoading} is true
    */
    const showAccountsOverlay: boolean = React.useMemo(() => {
        let show = false;
        // number of guessed accounts must be submitted first
        if (!profileData?.user?.noOfGuessedAccounts) {
            show = false
            return show
        }

        //emails are still being pulled & countedThreads is not yet up to quota
        if ((pullMailsState.isLoading && profileData?.user?.countedThreads < minCheckB4Redirect)) {
            show = true
        }
        if (isFakeLoading) {
            show = true;
        }

        return show
    }, [profileData, pullMailsState, isFakeLoading])

    const shouldRedirectToNextPage: boolean = React.useMemo(() => {
        let shouldRedirect = false;
        if (!profileData) {
            shouldRedirect = false;
            return shouldRedirect;
        }

        // number of guessed accounts must be submitted first
        if (!profileData?.user?.noOfGuessedAccounts) {
            shouldRedirect = false;
            return shouldRedirect;
        }

        // if mininum CheckB4Redirect has been exceeded

        if (profileData?.user?.countedThreads > minCheckB4Redirect) {
            shouldRedirect = true
        }

        return shouldRedirect;
    }, [profileData])

    const submit = async () => {
        const res: any = await updateProfile({
            accountsNo: percent
        })

        if (!res?.data) {
            toast.error("Error during submit", {
                position: "top-right",
                autoClose: 1500,
            })
        }

        //start fakeLoading
        setIsFakeLoading(true)
        setTimeout(() => {
            setIsFakeLoading(false)
        }, 5 * 1000)

        //if submit is successful
        if (res?.data) {
            /**
             * if initial pullMailAccounts() failed
             * call again!
            */
            if (!pullMailsState.isLoading && profileData?.user?.countedThreads < minCheckB4Redirect) {
                await pullMailAccounts()
            }
        }
    }

    const onLogout = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            // Sign-out successful.
            dispatch(logout({}))
        }).catch((error) => {
            // An error happened.
            console.log('error in signout')
        });
    }

    const pullMailAccounts = async () => {

        let res: any = await pullMails({
            accessToken: userState.accessToken,
        })


        /**
         * in case of Google auth access token expiry
         *  -retrieve a new accessToken using firebase auth
         */
        if (res?.error?.data?.message == "Invalid Credentials") {
            const cred: any = await requestPermissions();

            if (cred?.accessToken) {
                res = await pullMails({
                    accessToken: cred?.accessToken,
                })
            } else {
                onLogout()
            }

        }

        if (!res?.data) {
            toast.error("Error while fetching accounts\nPlease ensure you granted email permissions at login", {
                position: "top-right",
                autoClose: 1500,
            })
        }
    }

    const requestPermissions = async () => {
        const auth = getAuth();
        const credential = await signInWithPopup(auth, googleProvider)
            .then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const accessToken = credential?.accessToken;
                dispatch(updateToken({ accessToken: accessToken }))

                // The signed-in user info.
                return credential

            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
                return {
                    error
                }

            });

        return credential
    }


    const onChange = (value: number) => {
        setPercent(value)

        if (!touched) {
            setTouched(true)
        }
    }

    /**
     * if loading render blank
     */
    if (profileLoading) {
        return <div className='h-full flex flex-col justify-center items-center sm:pb-10 flex-1'>
            <Spinner />
        </div>
    }


    if (showAccountsOverlay) {
        return <AccountsLoadingOverlay />
    }

    /**
* if user already has accounts data, it means user already has passed these steps
*
* conditions to pass before redirect
* - profile data must be loaded
* - profile must contain estimated accounts no
* - 100 mail threads must have been pulled successfully
*
**/

    if (shouldRedirectToNextPage) {
        return <Navigate to="/complete-reg" replace state={{ from: location }} />;
    }



    return <div className='h-full flex flex-col sm:justify-around sm:pb-10 flex-1'>
        <div className='mt-10 sm:mt-[0] flex flex-col justify-around items-center space-y-4 sm:space-y-7 mx-[35px] sm:mx-[150px] md:mx-[200px]'>
            <div className='text-[#0304244D] text-[18px]'>
                Étape 4
            </div>
            <div className='text-center text-[32px] sm:text-[30px] md:text-[40px] sm:max-w-[65%] font-[500] sm:leading-[48px]'>
                À votre avis, combien de comptes avez-vous <span className='text-mavien-purple'>sur internet </span>?
            </div>
            <div className='font-semibold text-[36px]  text-center leading-10 pt-10'>
                <div className='text-[48px] font-[600] text-mavien-purple'>{percent}</div>
                comptes
            </div>
            <StepsSlider
                percent={16.6}
                onChange={onChange}
                defaultValue={percent}
            />

            <AppButton
                title={'Valider'}
                onClick={submit}
                disabled={!touched ? true : false}
            />
            {updateProfileState.isLoading && <Spinner />}
        </div>
    </div>
}

export default Steps