import * as React from 'react'

import * as JsSvg from '../assets/js_svgs'

import socialIcons from '../assets/images/socialIcons.png'

import Lottie from "lottie-react";
import animationPurple from "../assets/animations/animationPurple.json";

const LottieAnimation = () => <Lottie animationData={animationPurple} loop={true} />;

const AccountsLoadingOverlay = () => {

    return <div className='flex-1 flex flex-col sm:justify-center'>
        <div className='h-100 flex flex-col justify-around items-center my-[62px] md:my-[40px]'>
            <div className='h-[300px] w-[300px] md:h-[31%] md:w-[31%] mb-[42px] md:mb-[50px]'>
                <LottieAnimation />
            </div>
            <div className='font-[500] font-outfit text-center text-[32px] sm:text-[40px] '>
                Nous analysons votre <span className='text-mavien-purple block sm:inline'>vie numérique</span>
            </div>


        </div>
    </div>
}

export default AccountsLoadingOverlay